define("pilasbloques/templates/components/pilas-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aFhcKgSy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[7,\"label\",true],[10,\"class\",\"switch\"],[11,\"title\",[22,\"tooltip\"]],[8],[0,\"\\n    \"],[5,\"input\",[],[[\"@type\",\"@id\",\"@checked\",\"@disabled\"],[\"checkbox\",[22,\"element-id\"],[22,\"isChecked\"],[22,\"isDisabled\"]]]],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"slider round\"],[8],[7,\"i\",true],[11,\"class\",[29,[\"slider-icon \",[22,\"icon\"]]]],[8],[9],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/pilas-toggle.hbs"
    }
  });

  _exports.default = _default;
});