define("pilasbloques/components/pilas-cargando", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['contenido-principal', 'pilas-cargando-container']
  });

  _exports.default = _default;
});