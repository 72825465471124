define("pilasbloques/templates/desafios/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EAJ8U0LX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"contenido-principal\"],[8],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"Elegí uno de los libros de la barra superior\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/desafios/index.hbs"
    }
  });

  _exports.default = _default;
});