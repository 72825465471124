define("pilasbloques/components/pilas-splitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pilas-splitter'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      $(window).resize(function () {
        _this.fixLayout();
      });
      this.$('#splitter').on("mousedown", function (event) {
        event.preventDefault();
        $('#over-splitter').show();
        var initialX = event.pageX;
        var initialWidth = $(_this.panel).width();
        $('#over-splitter').on("mousemove", function (event) {
          var dx = event.pageX - initialX;
          var newWidth = initialWidth + dx; // Aplica límites de tamaño, entre 200 y 800.

          newWidth = Math.max(newWidth, 200);
          newWidth = Math.min(newWidth, 800);
          $(_this.panel).width(newWidth);
          $(window).trigger('resize');
          window.dispatchEvent(new Event('resize'));
        });
        $('.over-splitter').on("mouseup", function () {
          $('.over-splitter').off("mousemove");
          $('.over-splitter').hide();
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      $(window).off('resize');
    },
    fixLayout: function fixLayout() {
      var width = $(this.iframe).width();
      var height = width * 1.1428;
      $(this.iframe).height(height);
      $(this.ayuda).css('top', height);
    }
  });

  _exports.default = _default;
});