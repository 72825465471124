define("pilasbloques/components/modal-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      ocultar: function ocultar() {
        if (this.close) this.close();
      }
    }
  });

  _exports.default = _default;
});