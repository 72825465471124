define("pilasbloques/templates/desafio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lxXel3Kj",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"pilas-editor\",null,[[\"pilas\",\"model\",\"persistirSolucionEnURL\",\"codigo\",\"onReady\",\"debeMostrarFinDeDesafio\",\"onChangeWorkspace\"],[[24,[\"pilas\"]],[24,[\"model\"]],false,[24,[\"codigo\"]],[28,\"action\",[[23,0,[]],\"cuandoCargaPilas\"],null],true,[28,\"action\",[[23,0,[]],\"onChangeWorkspace\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/desafio.hbs"
    }
  });

  _exports.default = _default;
});