define("pilasbloques/services/twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //const URL = "http://localhost:3000/sendMessage";
  var URL_SEND_MESSAGE = "http://104.131.245.133:9914/sendMessage";

  var _default = Ember.Service.extend({
    compartir: function compartir(mensaje, imagen) {
      return new Promise(function (resolve, reject) {
        $.ajax({
          url: URL_SEND_MESSAGE,
          type: 'POST',
          dataType: 'json',
          contentType: "application/json",
          data: JSON.stringify({
            message: mensaje,
            media: imagen
          }),
          success: function success(res) {
            resolve(res);
          },
          error: function error(xhr) {
            console.error(xhr.responseText);
            reject(xhr.responseText);
          }
        });
      });
    }
  });

  _exports.default = _default;
});