define("pilasbloques/services/curso-api", ["exports", "pilasbloques/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    guardar: function guardar(parametros) {
      return new Promise(function (success, reject) {
        var data = {
          hash: parametros.hash,
          desafio: parametros.actividad,
          usuario: parametros.idAlumno,
          xml: parametros.codigo_xml
        };
        $.ajax({
          url: "".concat(_environment.default.cursoBackendURL, "/soluciones/"),
          contentType: 'application/json',
          type: "post",
          data: JSON.stringify(data)
        }).done(success).fail(reject);
      });
    },
    obtener_solucion_xml_desde_hash: function obtener_solucion_xml_desde_hash(hash) {
      return new Promise(function (success, reject) {
        $.ajax({
          url: "".concat(_environment.default.cursoBackendURL, "/soluciones/").concat(hash),
          contentType: 'application/json',
          type: "get"
        }).done(function (result) {
          success(result.data[0].xml);
        }).fail(reject);
      });
    }
  });

  _exports.default = _default;
});