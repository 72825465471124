define("pilasbloques/models/libro", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    titulo: _emberData.default.attr('string'),
    nombre: _emberData.default.attr('string'),
    descripcion: _emberData.default.attr('string'),
    grupos: (0, _relationships.hasMany)('grupo'),
    modoLecturaSimple: _emberData.default.attr('boolean'),
    desafiosCortos: _emberData.default.attr('boolean'),
    oculto: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});