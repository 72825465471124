define("pilasbloques/controllers/test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actividad: {
      iniciarEscena: function iniciarEscena() {
        var fondo = new pilas.fondos.Tarde(); // jshint ignore:line

        pilas.escena_actual().minZ = function () {
          return this.stage.children[this.stage.children.length - 1].z;
        };

        var p = new pilas.actores.Mono();
        p.transparencia = 50;
        var tablero = new Tablero(0, -40, {
          texto: "Hola?"
        }); // jshint ignore:line

        var tablero2 = new Tablero(100, -40, {
          texto: "Hola?"
        }); // jshint ignore:line

        var a = new pilas.actores.Aceituna();
        a.z = 10;
        a.x = [80];
        a.y = [20];
      },
      iniciarBlockly: function iniciarBlockly() {},
      finalizaCargarBlockly: function finalizaCargarBlockly() {}
    }
  });

  _exports.default = _default;
});