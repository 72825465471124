define("pilasbloques/mirage/fixtures/grupos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jshint esversion: 6 */
  var _default = [{
    id: 1,
    titulo: 'Autómatas, comandos, procedimientos y repetición',
    desafioIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  }, {
    id: 2,
    titulo: 'Alternativa condicional',
    desafioIds: [13, 14, 15, 16, 17, 18]
  }, {
    id: 3,
    titulo: 'Repetición condicional',
    desafioIds: [19, 20, 21, 22, 23, 24, 25]
  }, {
    id: 4,
    titulo: 'Sensores Numéricos',
    desafioIds: [26, 27]
  }, {
    id: 5,
    titulo: 'Parametrización de soluciones',
    desafioIds: [28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 130, 131, 132, 133, 134, 135, 136]
  }, {
    id: 'manual1cPrimaria3',
    titulo: 'Capítulo 3: Programando en la computadora'
  }, {
    id: 'manual1cPrimaria3.1.2',
    titulo: 'Dieta a base de churrascos',
    desafioIds: [201, 202, 203, 204, 205, 206]
  }, {
    id: 'manual1cPrimaria3.1.3',
    titulo: 'Coty empieza a dibujar',
    desafioIds: [207, 208, 209, 210, 211, 212, 213]
  }, {
    id: 'manual1cPrimaria3.1.4',
    titulo: '¡Marche una de lechuga y tomate!',
    desafioIds: [214, 215]
  }, {
    id: 'manual1cPrimaria3.2.2',
    titulo: 'La ensalada secreta',
    desafioIds: [216, 217, 218, 219]
  }, {
    id: 'manual1cPrimaria3.2.3',
    titulo: '¡Problemas para comer!',
    desafioIds: [220, 221, 222, 223, 224]
  }, {
    id: 'manual1cPrimaria3.I',
    titulo: 'Las palabras de Toto',
    desafioIds: [225, 226, 227, 228, 229]
  }, {
    id: 'manual1cPrimaria4',
    titulo: 'Capítulo 4: Repetición'
  }, {
    id: 'manual1cPrimaria4.1.3',
    titulo: 'Más churrascos para Duba',
    desafioIds: [230, 231, 232]
  }, {
    id: 'manual1cPrimaria4.1.4',
    titulo: 'Las líneas de Coty',
    desafioIds: [233, 234, 235]
  }, {
    id: 'manual1cPrimaria4.2.3',
    titulo: 'Corregimos los programas',
    desafioIds: [236, 237, 238, 239]
  }, {
    id: 'manual1cPrimaria4.I',
    titulo: 'Lita, a puro vegetal',
    desafioIds: [240, 241]
  }, {
    id: 'manual1cPrimaria5',
    titulo: 'Capítulo 5: Alternativa condicional'
  }, {
    id: 'manual1cPrimaria5.1.3',
    titulo: 'Sólo en ciertas ocasiones',
    desafioIds: [242, 243, 244]
  }, {
    id: 'manual1cPrimaria5.1.4',
    titulo: '¿Y si no?',
    desafioIds: [245, 246, 247]
  }, {
    id: 'manual1cPrimaria5.2.1',
    titulo: 'Más churrascos y ensaladas',
    desafioIds: [248, 249, 250]
  }, {
    id: 'manual1cPrimaria5.I',
    titulo: 'Agente Secreto Topotopo',
    desafioIds: [251, 252, 253, 254]
  }, {
    id: 'manual1cPrimariaOtros',
    titulo: 'Otros',
    desafioIds: [255]
  }, {
    id: 'manual1cPrimariaCapturasCap3',
    titulo: 'Desafíos para hacer capturas / Capítulo 3',
    desafioIds: ['paracaptura01', 'paracaptura02', 'paracaptura03', 'paracaptura04', 'paracaptura05', 'paracaptura06', 'paracaptura07', 'paracaptura08', 'paracaptura09', 'paracaptura10', 'paracaptura11']
  }, {
    id: 'manual1cPrimariaCapturasCap4',
    titulo: 'Desafíos para hacer capturas / Capítulo 4',
    desafioIds: ['paracaptura12', 'paracaptura13', 'paracaptura14', 'paracaptura15', 'paracaptura16', 'paracaptura17']
  }, {
    id: 'manual1cPrimariaCapturasCap5',
    titulo: 'Desafíos para hacer capturas / Capítulo 5',
    desafioIds: ['paracaptura18', 'paracaptura19', 'paracaptura20', 'paracaptura21', 'paracaptura22', 'paracaptura23', 'paracaptura24', 'paracaptura25', 'paracaptura26', 'paracaptura27', 'paracaptura28', 'paracaptura29']
  }];
  _exports.default = _default;
});