define("pilasbloques/templates/libros/ver-libro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5hfj6Mab",
    "block": "{\"symbols\":[\"grupo\",\"desafio\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"contenido-principal \",[28,\"if\",[[24,[\"model\",\"modoLecturaSimple\"]],\"aplicar-modo-lectura-simple\"],null]]]],[8],[0,\"\\n\\n  \"],[7,\"h1\",true],[8],[0,\"Desafíos del \"],[1,[24,[\"model\",\"titulo\"]],false],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"libros\"]],{\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-chevron-left\"],[8],[9],[0,\" Volver a la lista de libros\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\",\"grupos\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[23,1,[\"titulo\"]],false],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,1,[\"desafios\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"pilas-desafio\",null,[[\"model\"],[[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"p\",true],[10,\"class\",\"mensaje-sin-desafios\"],[8],[0,\"Lo siento, no hay desafíos para este manual aún.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"grupos\",\"isSettled\"]]],null,{\"statements\":[[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"pilas-cargando\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/libros/ver-libro.hbs"
    }
  });

  _exports.default = _default;
});