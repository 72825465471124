define("pilasbloques/routes/desafios/curso-docente", ["exports", "pilasbloques/routes/desafios/curso-alumno"], function (_exports, _cursoAlumno) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cursoAlumno.default.extend({
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);

      return this.cursoAPI.obtener_solucion_xml_desde_hash(model.hash).then(function (solucion_xml) {
        model.set("solucion", btoa(solucion_xml));
      });
    }
  });

  _exports.default = _default;
});