define("pilasbloques/router", ["exports", "pilasbloques/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });
  Router.map(function () {
    this.route('acercade');
    this.route('desafio', {
      path: '/desafio/:desafio_id'
    });
    /* Rutas para el curso online 2016 (moodle) */

    this.route('desafios', function () {
      this.route('cursoAlumno', {
        path: "/cursoAlumno/:hash"
      });
      this.route('cursoDocente', {
        path: "/cursoDocente/:hash"
      });
      this.route('desafioPorNombre', {
        path: '/:nombreDelDesafio'
      });
    });
    this.route('libros', function () {
      this.route('verLibro', {
        path: ":libro_id"
      });
    });
    this.route('galeria');
  });
  Router.reopen({
    notifyGoogleAnalytics: Ember.on("didTransition", function () {
      if (ga && _environment.default.googleAnalyticsEnabled) {
        var url = this.url;
        ga('send', 'pageview', {
          page: url,
          title: url
        });
      }
    })
  });
  var _default = Router;
  _exports.default = _default;
});