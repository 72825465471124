define("pilasbloques/components/pilas-solution-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VERSION_DEL_FORMATO_DE_ARCHIVO = 2;

  var _default = Ember.Component.extend({
    tagName: 'span',
    cuandoSelecciona: null,
    actividad: null,
    workspace: null,
    xml: null,
    inElectron: typeof process !== "undefined",
    //TODO: Mover a un service y reemplazar a todos los lugares donde se usa.
    version: function version() {
      return VERSION_DEL_FORMATO_DE_ARCHIVO;
    },
    leerSolucionWeb: function leerSolucionWeb(archivo) {
      var _this = this;

      var reader = new FileReader();
      return new Promise(function (resolve, reject) {
        reader.onerror = function (err) {
          return reject(err);
        };

        reader.onload = function (event) {
          return resolve(event.target.result);
        };

        reader.readAsText(archivo);
      }).then(function (contenido) {
        return _this.cargarSolucion(contenido);
      });
    },
    leerSolucionFS: function leerSolucionFS(archivo) {
      var _this2 = this;

      var fs = Promise.promisifyAll(require("fs"));
      return fs.readFileAsync(archivo, 'utf-8').then(function (contenido) {
        return _this2.cargarSolucion(contenido);
      });
    },
    cargarSolucion: function cargarSolucion(contenido) {
      // let regex_file = /\.spbq$/
      // let regex_version = /^\d+$/
      var data = null;
      var solucion = null;

      try {
        data = JSON.parse(contenido);
        solucion = atob(data.solucion);
      } catch (e) {
        console.error(e);
        throw "Lo siento, este archivo no tiene una solución de Pilas Bloques.";
      }

      this.set('workspace', solucion);
      var errors = [];

      if (this.get("actividad.nombre") !== data.actividad) {
        errors.push("Cuidado, el archivo indica que es para otra actividad (".concat(data.actividad, "). Se cargar\xE1 de todas formas, pero puede fallar."));
      }

      if (VERSION_DEL_FORMATO_DE_ARCHIVO > data.version) {
        errors.push("Cuidado, el archivo indica que es de una versión anterior. Se cargará de todas formas, pero te sugerimos que resuelvas nuevamente el ejercicio y guardes un nuevo archivo.");
      }

      if (errors.length !== 0) {
        throw errors.join('\n');
      }
    },
    openElectronLoadDialog: function openElectronLoadDialog() {
      var dialog = require("electron").remote.dialog;

      var archivos = dialog.showOpenDialog({
        //TODO: this config exists in packaging/electron.js
        properties: ['openFile'],
        filters: [{
          name: 'Solución de Pilas Bloques',
          extensions: ['spbq']
        }, {
          name: 'Todos los archivos',
          extensions: ['*']
        }]
      });

      if (archivos) {
        this.leerSolucionFS(archivos[0]).catch(alert);
      }
    },
    descargar: function descargar(text, name, type) {
      var a = document.getElementById("placeholder");
      var file = new Blob([text], {
        type: type
      });
      a.href = URL.createObjectURL(file);
      a.download = name;
      a.type = type;
      a.click();
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this.fileInput().change(function (event) {
        var archivo = event.target.files[0];

        if (archivo) {
          _this3.leerSolucionWeb(archivo).catch(alert);
        }

        _this3.limpiarInput(); // Fuerza a que se pueda cargar dos o más veces el mismo archivo


        return false;
      });
    },
    fileInput: function fileInput() {
      return this.$("#cargarActividadInput");
    },
    limpiarInput: function limpiarInput() {
      this.fileInput().value = null;
    },
    actions: {
      abrirSolucion: function abrirSolucion() {
        if (this.inElectron) {
          this.openElectronLoadDialog();
        } else {
          this.fileInput().click();
        }
      },
      guardarSolucion: function guardarSolucion() {
        var activityName = this.get("actividad.nombre");
        var fileName = "".concat(activityName, ".spbq");
        var contenido = {
          version: VERSION_DEL_FORMATO_DE_ARCHIVO,
          actividad: activityName,
          solucion: btoa(this.xml)
        };
        this.descargar(JSON.stringify(contenido), fileName, 'application/octet-stream');
      }
    }
  });

  _exports.default = _default;
});