define("pilasbloques/components/pilas-desafio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['desafio'],
    nombre: null,
    deshabilitada: false,
    actions: {
      abrir: function abrir() {
        if (this.onSelect) this.onSelect(this.nombre);
      }
    }
  });

  _exports.default = _default;
});