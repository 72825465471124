define("pilasbloques/templates/components/info-reportar-problema", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P7rcBc7K",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\"¿Tenés algún problema?\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"¿Pensás que este ejercicio tiene un error?\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"Podés escribirnos a \"],[7,\"a\",true],[10,\"href\",\"mailto:pilasbloques@program.ar\"],[8],[0,\"pilasbloques@program.ar\"],[9],[0,\" \"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"Si tenés una cuenta en Github podés también hacernos un reporte de error directamente en el \"],[4,\"pilas-link\",null,[[\"href\"],[\"https://github.com/Program-AR/pilas-bloques/issues\"]],{\"statements\":[[0,\"Issue Tracker\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/info-reportar-problema.hbs"
    }
  });

  _exports.default = _default;
});