define("pilasbloques/routes/desafios/desafio-por-nombre", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;

      return this.store.findAll("desafio").then(function (data) {
        // TODO: reemplazar la linea anterior (findAll) y la siguiente
        //       por una consulta a ember-data más específica, como la que
        //       realiza findRecord, que solo debería traer un solo registro.
        //
        //       (esto está así ahora porque se debe corregir mirage antes).
        var model = data.findBy('nombre', params.nombreDelDesafio);

        if (!model) {
          throw new Error("No existe una actividad con el nombre ".concat(params.nombreDelDesafio));
        }

        return _this.transitionTo("desafio", model);
      });
    }
  });

  _exports.default = _default;
});