define("pilasbloques/templates/galeria", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NPnPiYeI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"contenido-principal\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[0,\"Galería de bloques\"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"Esta sección muestra los bloques que actualmente migramos a\\n    la nueva versión de blockly.\"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"Esta información es temporal, y solo estará visible en el branch\\n  \"],[7,\"code\",true],[8],[0,\"feature/actualizar-blockly\"],[9],[0,\".\"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"padding\"],[8],[0,\"\\n    \"],[1,[28,\"ember-blockly-catalog\",null,[[\"current_block\"],[[24,[\"current_block\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/galeria.hbs"
    }
  });

  _exports.default = _default;
});