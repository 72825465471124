define("pilasbloques/templates/components/modal-ayuda", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oCBP/BeD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"mostrar\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"targetAttachment\",\"translucentOverlay\"],[[28,\"action\",[[23,0,[]],\"close\"],null],\"center\",true]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"modal-title\",null,[[\"title\",\"close\"],[\"Ayuda\",[28,\"action\",[[23,0,[]],\"close\"],null]]]],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"Esta aplicación te presentará varios desafíos que se pueden\\n      resolver conectando bloques.\"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"El primer paso es abrir la sección de Desafíos:\"],[9],[0,\"\\n\\n    \"],[7,\"img\",true],[10,\"src\",\"imagenes/ayuda/seleccionar.gif\"],[10,\"class\",\"img-border\"],[8],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"Los bloques están agrupados en categorías, usá la barra lateral\\n      para desplegar los bloques.\"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"Luego podés arrastrar y soltar los bloques sobre el área\\n      de trabajo.\"],[9],[0,\"\\n\\n    \"],[7,\"img\",true],[10,\"src\",\"imagenes/ayuda/arrastrar.gif\"],[10,\"class\",\"img-border\"],[8],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"Una vez que tengas tu programa listo pulsá el botón ejecutar\\n      continuar.\"],[9],[0,\"\\n\\n    \"],[7,\"img\",true],[10,\"src\",\"imagenes/ayuda/ejecutar.gif\"],[10,\"class\",\"img-border\"],[8],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"Ah, y los bloques se pueden borrar arrastrándolos a la papelera.\"],[9],[0,\"\\n\\n    \"],[7,\"img\",true],[10,\"src\",\"imagenes/ayuda/borrar.gif\"],[10,\"class\",\"img-border\"],[8],[9],[0,\"\\n\\n    \"],[7,\"h3\",true],[8],[0,\"Reportar un problema\"],[9],[0,\"\\n    \"],[1,[22,\"info-reportar-problema\"],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/modal-ayuda.hbs"
    }
  });

  _exports.default = _default;
});