define("pilasbloques/components/pilas-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['contenedor-pilas-editor'],
    persistirSolucionEnURL: false,
    blocksGallery: Ember.inject.service(),
    cargando: true,
    showCode: false,
    modoLecturaSimple: Ember.computed('model', function () {
      return this.get('model.grupo.libro.modoLecturaSimple');
    }),
    didInsertElement: function didInsertElement() {
      this.blocksGallery.start();
    },
    actions: {
      onReady: function onReady(pilas) {
        if (this.onReady) this.onReady(pilas);
        this.set('cargando', false);

        if (this.modoLecturaSimple) {
          pilas.cambiarAModoDeLecturaSimple();
        }
      }
    }
  });

  _exports.default = _default;
});