define("pilasbloques/services/highlighter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /// Este service va recibiendo los Ids de los bloques que se ejecutan y SOLAMENTE se encarga del highlighting.
  /// Particularmente, tiene la lógica de highligh para los procedimientos.
  /// No sabe nada sobre qué hacen o cuándo se ejecutará cada bloque.
  var _default = Ember.Service.extend({
    blocks: [],
    step: function step(blockId) {
      var block = this._workspace().getBlockById(blockId);

      if (!block) {
        console.warn("Couldn't highlight block id: ".concat(blockId));
        return;
      }

      this._removeLastBlockIfEndOfModule();

      this._removePreviousBlockIfContinue(block);

      if (!this._ignore(block)) {
        this.blocks.push(block);
      }

      this._updateHighlight();
    },
    clear: function clear() {
      this.blocks.length = 0;

      this._clearHighlight();
    },
    _lastBlock: function _lastBlock() {
      return this.blocks[this.blocks.length - 1];
    },
    _removeLastBlockIfEndOfModule: function _removeLastBlockIfEndOfModule() {
      if (this._shouldRemoveLastBlock()) {
        this.blocks.pop();
      }
    },
    _removePreviousBlockIfContinue: function _removePreviousBlockIfContinue(block) {
      if (block.getParent() === this._lastBlock()) {
        this.blocks.pop();
      }
    },
    _ignore: function _ignore(block) {
      return this._isModuleDefinition(block);
    },
    _shouldRemoveLastBlock: function _shouldRemoveLastBlock() {
      return this._lastBlock() && this._isEndOfModule(this._lastBlock()) && !this._isProcedureCall(this._lastBlock());
    },
    _isEndOfModule: function _isEndOfModule(block) {
      return !block.getNextBlock();
    },
    _isModuleDefinition: function _isModuleDefinition(block) {
      return !block.getParent();
    },
    _isProcedureCall: function _isProcedureCall(block) {
      return !!block.defType_;
    },
    _updateHighlight: function _updateHighlight() {
      var _this = this;

      this._clearHighlight();

      this.blocks.forEach(function (b) {
        return _this._workspace().highlightBlock(b.id, true);
      });
    },
    _clearHighlight: function _clearHighlight() {
      this._workspace().highlightBlock();
    },
    _workspace: function _workspace() {
      return Blockly.getMainWorkspace();
    }
  });

  _exports.default = _default;
});