define("pilasbloques/models/desafio", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    nombre: (0, _attr.default)('string'),
    titulo: (0, _attr.default)('string'),
    imagen: (0, _attr.default)('string'),
    deshabilitado: (0, _attr.default)('boolean'),
    enunciado: (0, _attr.default)('string'),
    consignaInicial: (0, _attr.default)('string'),
    escena: (0, _attr.default)('string'),
    debeFelicitarse: (0, _attr.default)(),
    estiloToolbox: (0, _attr.default)('string'),
    grupo: (0, _relationships.belongsTo)('grupo'),
    bloques: (0, _attr.default)(),
    solucionInicial: (0, _attr.default)('string'),
    debugging: (0, _attr.default)('boolean'),
    nombreImagen: Ember.computed('imagen', 'nombre', function () {
      return "".concat(this.imagen || this.nombre || 'proximamente', ".png");
    })
  });

  _exports.default = _default;
});