define("pilasbloques/templates/desafios/curso-alumno-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2/Hs4D6u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"contenido-principal\"],[8],[0,\"\\n\\n  \"],[7,\"h1\",true],[8],[0,\":(\"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"Lo siento, la dirección URL no se puede decodificar,\\n  posiblemente el parámetro \"],[7,\"code\",true],[8],[0,\"hash\"],[9],[0,\" tenga un error\\n  de formato.\"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"Por favor contacte al docente del curso para informar\\n  este error.\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/desafios/curso-alumno-error.hbs"
    }
  });

  _exports.default = _default;
});