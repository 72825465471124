define("pilasbloques/templates/components/pilas-libro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pl7Ky4K+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"libros.verLibro\",[24,[\"libro\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"libro\",\"nombre\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"src\",[29,[\"imagenes/libros/\",[24,[\"libro\",\"nombre\"]],\".png\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"h2\",true],[8],[1,[24,[\"libro\",\"titulo\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"p\",true],[8],[1,[24,[\"libro\",\"descripcion\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/pilas-libro.hbs"
    }
  });

  _exports.default = _default;
});