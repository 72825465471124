define("pilasbloques/controllers/desafios/curso-docente", ["exports", "pilasbloques/controllers/desafios/curso-alumno"], function (_exports, _cursoAlumno) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cursoAlumno.default.extend({});

  _exports.default = _default;
});