define("pilasbloques/templates/components/pilas-botones-zoom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XOPozfto",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"canZoomOut\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",\"btn btn-success btn-rect\"],[3,\"action\",[[23,0,[]],\"zoomOut\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-minus\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"disabled\",\"\"],[10,\"class\",\"btn btn-success btn-rect\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-minus\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"span\",false],[12,\"class\",\"zoom-label\"],[3,\"action\",[[23,0,[]],\"zoomRestore\"]],[8],[1,[22,\"zoomValue\"],false],[0,\"%\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"canZoomIn\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",\"btn btn-success btn-rect\"],[3,\"action\",[[23,0,[]],\"zoomIn\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"disabled\",\"\"],[10,\"class\",\"btn btn-success btn-rect\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/pilas-botones-zoom.hbs"
    }
  });

  _exports.default = _default;
});