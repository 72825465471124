define("pilasbloques/mirage/models/grupo", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jshint esversion: 6 */
  var _default = _emberCliMirage.Model.extend({
    desafios: (0, _emberCliMirage.hasMany)('desafio', {
      inverseOf: 'grupo'
    }),
    libro: (0, _emberCliMirage.belongsTo)('libro')
  });

  _exports.default = _default;
});