define("pilasbloques/components/pilas-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pilas-canvas-container'],
    classNameBindings: ['media.isMobile:media-mobile'],
    iframeElement: null,
    escena: null,
    pilas: null,

    /* Se espera que este atributo se defina al
     * llamar al componente y es obligatorio. */
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, this.initElement);
    },
    willDestroyElement: function willDestroyElement() {
      if (this.pilas) {
        this.pilas.liberarRecursos();
      }
    },
    initElement: function initElement() {
      var _this = this;

      var iframeElement = this.element.querySelector('#innerIframe');
      this.set("iframeElement", iframeElement);

      this.iframeElement.onload = function () {
        if (_this.pilas) {
          _this.pilas.inicializarPilas(iframeElement, {
            width: 420,
            height: 480
          }, _this.escena).then(function (pilas) {
            if (_this.escena) {
              _this.pilas.inicializarEscena(iframeElement, _this.escena);
            } else {
              console.warn("No especificó una escena para cargar en pilas-canvas.");
            }
            /*
             * Invoca a la acción "onReady" que envía el objeto pilas listo
             * para ser utilizado.
             *
             */


            if (_this.onReady) {
              _this.onReady(pilas);
            } else {//console.warn("Se a iniciado el componente pilas-canvas sin referencia a la acción onLoad.");
            }
          });
        } else {
          console.warn("No has enviado el objeto pilas.");
        } // onLoad solo se utiliza dentro de la batería de tests. Este
        // componente se tendría que usar mediante el servicio "pilas"
        // en cualquier otro lugar.


        if (_this.onLoad) _this.onLoad({
          iframeElement: iframeElement
        });
      };
    },
    reloadIframe: function reloadIframe(onLoadFunction) {
      this.iframeElement.onload = onLoadFunction;
      this.iframeElement.contentWindow.location.reload(true);
    },
    actions: {
      execute: function execute(code) {
        var _this2 = this;

        this.reloadIframe(function () {
          alert("Ha cargado el código y está todo listo!");

          _this2.iframeElement.contentWindow.eval(code);
        });
      },
      clear: function clear() {
        this.reloadIframe();
      },
      quitFullscreen: function quitFullscreen() {
        this.set('inFullScreen', false);
      },
      enterFullscreen: function enterFullscreen() {
        this.set('inFullScreen', true);
      }
    }
  });

  _exports.default = _default;
});