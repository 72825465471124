define("pilasbloques/components/pilas-botones-zoom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['nw-zoom'],
    zoomValue: 100,
    zoom: Ember.inject.service(),
    canZoomIn: Ember.computed('zoomValue', function () {
      return this.zoomValue < 120;
    }),
    canZoomOut: Ember.computed('zoomValue', function () {
      return this.zoomValue > 80;
    }),
    cambiarZoom: Ember.observer('zoomValue', function () {
      this.zoom.setValue(this.zoomValue);
      this.aplicarZoom((this.zoomValue - 100) / 10);
    }),
    aplicarZoom: function aplicarZoom(zoomLevel) {
      document.body.style.zoom = 100 + zoomLevel * 10 + "%";
    },
    onStart: Ember.on('init', function () {
      this.set('zoomValue', this.zoom.getValue());
      this.cambiarZoom();
    }),
    actions: {
      zoomIn: function zoomIn() {
        this.set('zoomValue', this.zoomValue + 10);
      },
      zoomOut: function zoomOut() {
        this.set('zoomValue', this.zoomValue - 10);
      },
      zoomRestore: function zoomRestore() {
        this.set('zoomValue', 100);
      }
    }
  });

  _exports.default = _default;
});