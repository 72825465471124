define("pilasbloques/services/zoom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    zoom: 100,
    getValue: function getValue() {
      return this.zoom;
    },
    setValue: function setValue(zoomValue) {
      this.set('zoom', zoomValue);
    }
  });

  _exports.default = _default;
});