define("pilasbloques/services/pilas", ["exports", "pilasbloques/components/listaImagenes"], function (_exports, _listaImagenes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provee acceso a pilasweb y sus eventos.
   *
   * @public
   * @module PilasService
   */

  /**
   * Un servicio que provee métodos y eventos para comunicarse
   * con pilasweb y el componente pilas-canvas.
   * DEMO.
   *
   * Estos son los eventos que puede reportar el servicio:
   *
   * - terminaCargaInicial
   * - errorDeActividad
   *
   * @public
   * @class PilasService
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    iframe: null,
    actorCounter: 0,
    pilas: null,
    loading: true,
    inicializadorDeLaEscenaActual: null,
    temporallyCallback: null,

    /* almacena el callback para avisar si pilas
       se reinició correctamente. */

    /**
     * Instancia pilas-engine con los atributos que le envíe
     * el componente x-canvas.
     *
     * Este método realiza una conexión con el servicio pilas, y
     * se llamará automáticamente ante dos eventos: se agrega el
     * componente x-canvas a un template o se ha llamado a `reload`
     * en el servicio pilas.
     *
     * @public
     */
    inicializarPilas: function inicializarPilas(iframeElement, options, nombreOInicializadorDeEscena) {
      var _this = this;

      this.set("iframe", iframeElement);
      this.set("loading", true);
      return new Promise(function (success) {
        var width = options.width;
        var height = options.height; // Cuidado: esto hace que no se pueda cargar una escena diferente en esta instancia de pilas.
        // La razón es que se le pregunta a la escena qué imágenes precargar.

        var listaImagenesSerializada = _this.imagenesParaPrecargar(nombreOInicializadorDeEscena).join("|");

        var code = "\n        var canvasElement = document.getElementById('canvas');\n        var listaImagenes = \"".concat(listaImagenesSerializada, "\".split(\"|\");\n        var opciones = {ancho: ").concat(width, ",\n                        alto: ").concat(height, ",\n                        canvas: canvasElement,\n                        data_path: 'libs/data',\n                        imagenesExtra: listaImagenes,\n                        cargar_imagenes_estandar: false,\n                        silenciar_advertencia_de_multiples_ejecutar: true\n                      };\n\n        var pilas = pilasengine.iniciar(opciones);\n\n        pilas;\n      ");
        var pilas = iframeElement.contentWindow.eval(code);

        _this.conectarEventos();

        pilas.onready = function () {
          //this.get('actividad').iniciarEscena();
          //var contenedor = document.getElementById('contenedor-blockly');
          //this.get('actividad').iniciarBlockly(contenedor);
          //if (this.get('actividad')['finalizaCargarBlockly']) {
          //  this.get('actividad').finalizaCargarBlockly();
          //}
          success(pilas);
          /*
           * Si el usuario llamó a "reload" desde este servicio, tendría
           * que existir una promesa en curso, así que estas lineas se
           * encargan de satisfacer esa promesa llamando al callback success.
           */

          if (_this.temporallyCallback) {
            _this.temporallyCallback(pilas);

            _this.set("temporallyCallback", null);
          }

          _this.set("loading", false);
        };

        pilas.ejecutar();

        _this.cambiarFPS(100);
      });
    },
    imagenesParaPrecargar: function imagenesParaPrecargar(nombreOInicializadorDeEscena) {
      //Le pregunto a la escena qué imágenes va a necesitar
      var imagenes = this.evaluar("".concat(this.nombreDeEscena(nombreOInicializadorDeEscena), ".imagenesPreCarga()")); //Si la escena no las sabe, cargo todas:

      return imagenes.length ? imagenes : _listaImagenes.default;
    },
    nombreDeEscena: function nombreDeEscena(nombreOInicializadorDeEscena) {
      if (nombreOInicializadorDeEscena.indexOf('new') === -1) {
        // Significa que vino el nombre.
        return nombreOInicializadorDeEscena;
      } else {
        // Significa que hay una construcción en el string.
        // La expresión regular captura el nombre de la clase (\w+)
        // y el [1] accede al primer grupo de captura.
        return nombreOInicializadorDeEscena.match(/new\s+(\w+)\s*\(/)[1];
      }
    },

    /**
     * Libera los eventos y recursos instanciados por este servicio.
     *
     * @method liberarRecursos
     * @public
     */
    liberarRecursos: function liberarRecursos() {
      this.desconectarEventos();
    },

    /**
     * Captura cualquier mensaje desde el iframe y lo propaga
     * como un evento de ember evented.
     *
     * Los eventos que se originan en el iframe tienen la forma:
     *
     *     {
     *       tipo: "tipoDeMensaje"    # Cualquiera de los listados más arriba.
     *       detalle: [...]           # string con detalles para ese evento.
     *     }
     *
     * Sin embargo esta función separa esa estructura para que sea más
     * sencillo capturarla dentro de ember.
     *
     * Por ejemplo, si queremos capturar un error (como hace la batería de tests),
     * podemos escribir:
     *
     *     pilas.on('errorDeActividad', function(motivoDelError) {
     *       // etc...
     *     });
     *
     * @method contectarEventos
     * @private
     *
     */
    conectarEventos: function conectarEventos() {
      var _this2 = this;

      $(window).on("message.fromIframe", function (e) {
        var datos = e.originalEvent.data;

        _this2.trigger(datos.tipo, datos.detalle);
      });
    },

    /**
     * Se llama automáticamente para desconectar los eventos del servicio.
     *
     * @method desconectarEventos
     * @private
     */
    desconectarEventos: function desconectarEventos() {
      $(window).off("message.fromIframe");
    },
    inicializarEscena: function inicializarEscena(iframeElement, nombreOInicializador) {
      var inicializador = nombreOInicializador;

      if (inicializador.indexOf('new') === -1) {
        //Significa que vino un nombre de escena.
        inicializador = "new ".concat(inicializador, "()");
      }

      var codigo = "\n      var escena = ".concat(inicializador, ";\n      pilas.mundo.gestor_escenas.cambiar_escena(escena);\n    ");
      this.evaluar(codigo);
      this.set("inicializadorDeLaEscenaActual", inicializador);
    },

    /**
     * Evalúa código reiniciando completamente la biblioteca.
     *
     * @method ejecutarCodigo
     * @public
     */
    ejecutarCodigo: function ejecutarCodigo(codigo) {
      var _this3 = this;

      this.reiniciar().then(function () {
        var iframeElement = _this3.iframe;
        iframeElement.contentWindow.eval(codigo);
      });
    },

    /**
     * Retorna true si el problema está resuelto.
     *
     * @method estaResueltoElProblema
     * @public
     */
    estaResueltoElProblema: function estaResueltoElProblema() {
      return this.evaluar("pilas.escena_actual().estaResueltoElProblema();");
    },

    /**
     * Ejecuta el código reiniciando la escena rápidamente.
     *
     * @method ejecutarCodigoSinReiniciar
     * @public
     *
     * @todo convertir en método privado.
     */
    ejecutarCodigoSinReiniciar: function ejecutarCodigoSinReiniciar(codigo) {
      if (this.loading) {
        console.warn("Cuidado, no se puede ejecutar antes de que pilas cargue.");
        return;
      }

      var iframeElement = this.iframe;
      this.reiniciarEscenaCompleta();
      iframeElement.contentWindow.eval(codigo);
    },

    /**
     * Retorna una captura de pantalla de la escena en formato png/base64
     *
     * @method obtenerCapturaDePantalla
     * @public
     */
    obtenerCapturaDePantalla: function obtenerCapturaDePantalla() {
      var iframeElement = this.iframe;
      return iframeElement.contentWindow.document.getElementById('canvas').toDataURL('image/png');
    },

    /**
     * Realiza un reinicio rápido de la escena actual.
     *
     * @method reiniciarEscenaCompleta
     * @private
     */
    reiniciarEscenaCompleta: function reiniciarEscenaCompleta() {
      var iframeElement = this.iframe;
      iframeElement.contentWindow.eval("pilas.reiniciar();");
      this.inicializarEscena(iframeElement, this.inicializadorDeLaEscenaActual);
    },

    /**
     * Modifica la velocidad de las animaciones y la simulación.
     *
     * Este método es particularmente útil para ejecutar los tests de integración
     * super rápido.
     *
     * Por omisión pilas utiliza un temporizador a 60 FPS.
     *
     * @method cambiarFPS
     * @public
     *
     */
    cambiarFPS: function cambiarFPS(fps) {
      this.evaluar("pilas.setFPS(".concat(fps, ");"));
    },

    /**
     * Permite reiniciar pilas por completo.
     *
     * La acción de reinicio se realiza re-cargando el iframe
     * que contiene a pilas, así que se va a volver a llamar al
     * método `instanciarPilas` automáticamente.
     *
     * Este método retorna una promesa, que se cumple cuando pilas se
     * halla cargado completamente.
     *
     * @method reiniciar
     * @private
     */
    reiniciar: function reiniciar() {
      var _this4 = this;

      return new Promise(function (success) {
        if (_this4.loading) {
          console.warn("Cuidado, se está reiniciando en medio de la carga.");
        }

        _this4.set("loading", true);

        _this4.iframe.contentWindow.location.reload(true);
        /* Guarda el callback  para que se llame luego de la carga de pilas. */


        _this4.set("temporallyCallback", success);
      });
    },

    /**
     * Retorna la cantidad de actores en la escena con la etiqueta solicitada.
     *
     * @method contarActoresConEtiqueta
     * @public
     */
    contarActoresConEtiqueta: function contarActoresConEtiqueta(etiqueta) {
      var codigo = "\n      var actoresEnLaEscena = pilas.escena_actual().actores;\n\n      var actoresConLaEtiqueta = actoresEnLaEscena.filter(function(actor) {\n        return actor.tiene_etiqueta(\"".concat(etiqueta, "\");\n      });\n\n      actoresConLaEtiqueta.length;\n    ");
      return this.evaluar(codigo);
    },
    cambiarAModoDeLecturaSimple: function cambiarAModoDeLecturaSimple() {
      this.evaluar('pilas.cambiarAModoDeLecturaSimple()');
    },
    cambiarAModoDeLecturaNormal: function cambiarAModoDeLecturaNormal() {
      this.evaluar('pilas.cambiarAModoDeLecturaNormal()');
    },

    /**
     * Evalúa código directamente, sin reiniciar de ninguna forma.
     *
     * @method evaluar
     * @public
     */
    evaluar: function evaluar(codigo) {
      var iframeElement = this.iframe;
      return iframeElement.contentWindow.eval(codigo);
    },
    habilitarModoTurbo: function habilitarModoTurbo() {
      this.evaluar('ComportamientoConVelocidad').modoTurbo = true;
      this.evaluar('pilas').ponerVelocidadMaxima();
    },
    deshabilitarModoTurbo: function deshabilitarModoTurbo() {
      this.evaluar('ComportamientoConVelocidad').modoTurbo = false;
      this.evaluar('pilas').ponerVelocidadNormal();
    }
  });

  _exports.default = _default;
});