define("pilasbloques/services/interpreter-factory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    pilas: Ember.inject.service(),

    /**
     * Retorna un intérprete preparado para ejecutar el código que
     * se le envíe como argumento.
     *
     * El código se ejecutará de manera aislada, en un entorno protegido
     * sin acceso al exterior (no tendrá acceso a ember, pilas, window, ni nada...)
     * así que las únicas funciones a las que podrá acceder están detalladas
     * en la función _initFunction, que aparece más abajo.
     */
    crearInterprete: function crearInterprete(codigo, callback_cuando_ejecuta_bloque) {
      var _this = this;

      return new Interpreter(this.wrappearCodigo(codigo), function (interpreter, scope) {
        return _this._initFunction(interpreter, scope, callback_cuando_ejecuta_bloque);
      });
    },

    /**
     * Inicializa el intérprete y su scope inicial, para que
     * pueda usar funcioens como "hacer", "console.log" etc..
     */
    _initFunction: function _initFunction(interpreter, scope, callback_cuando_ejecuta_bloque) {
      var pilasService = this.pilas;
      var actor = pilasService.evaluar("pilas.escena_actual().automata;");

      var console_log_wrapper = function console_log_wrapper(txt) {
        txt = txt ? txt.toString() : '';
        return interpreter.createPrimitive(console.log(txt));
      };

      interpreter.setProperty(scope, 'console_log', interpreter.createNativeFunction(console_log_wrapper)); // Esto deberia estar en otro lado, es un comportamiento que lo unico que
      // hace es llamar a una función.

      var ComportamientoLlamarCallback = function ComportamientoLlamarCallback(args) {
        this.argumentos = args;

        this.iniciar = function () {};

        this.actualizar = function () {
          this.argumentos.callback();
          return true;
        };

        this.eliminar = function () {};
      }; // Genera la función "out_hacer" que se llamará dentro del intérprete.
      //
      // Esta función encadenará dos comportamientos para simplificar el uso
      // de funciones asincrónicas. Agregará el comportamiento que represente
      // la acción que el usuario quiere hacer con el actor y luego agregará
      // otro comportamiento para indicar que la tarea asincrónica terminó.
      //
      // Por ejemplo, si en el código se llama a la función hacer así:
      //
      //      hacer("Saltar", {});
      //      hacer("Caminar", {pasos: 20});
      //
      // Internamente la función hará que el actor primero "salte" y luego
      // "camine" 20 pasos.


      var hacer_wrapper = function hacer_wrapper(comportamiento, params, callback) {
        comportamiento = comportamiento ? comportamiento.toString() : '';
        params = params ? params.toString() : '';
        params = JSON.parse(params);
        var clase_comportamiento = pilasService.evaluar("\n        var comportamiento = null;\n\n        if (window['".concat(comportamiento, "']) {\n          comportamiento = ").concat(comportamiento, ";\n        } else {\n          if (pilas.comportamientos['").concat(comportamiento, "']) {\n            comportamiento = pilas.comportamientos['").concat(comportamiento, "'];\n          } else {\n            throw new Error(\"No existe un comportamiento llamado '").concat(comportamiento, "'.\");\n          }\n        }\n\n        comportamiento;\n      "));

        if (typeof params.receptor === 'string') {
          params.receptor = pilasService.evaluar("pilas.escena_actual().".concat(params.receptor));
        }

        actor.hacer_luego(clase_comportamiento, params);
        actor.hacer_luego(ComportamientoLlamarCallback, {
          callback: callback
        });
      };

      interpreter.setProperty(scope, 'out_hacer', interpreter.createAsyncFunction(hacer_wrapper));
      /**
       * Es el código que se ejecuta con una expresión (sensor, operación, etc.)
       */

      function out_evaluar(expr) {
        expr = expr ? expr.toString() : '';
        return interpreter.createPrimitive(pilasService.evaluar("\n        try {\n          var value = pilas.escena_actual().automata.".concat(expr, "\n        } catch (e) {\n          pilas.escena_actual().errorHandler.handle(e);\n        }\n\n        value")));
      }

      interpreter.setProperty(scope, 'evaluar', interpreter.createNativeFunction(out_evaluar));
      /**
       * Llama a callback_cuando_ejecuta_bloque con el id del bloque en ejecucion.
       */

      function out_highlightBlock(id) {
        id = id ? id.toString() : '';
        return interpreter.createPrimitive(callback_cuando_ejecuta_bloque.call(this, id));
      }

      interpreter.setProperty(scope, 'highlightBlock', interpreter.createNativeFunction(out_highlightBlock));
    },
    wrappearCodigo: function wrappearCodigo(codigo) {
      return js_beautify("\n        var actor_id = 'demo'; // se asume el actor receptor de la escena.\n\n        function hacer(id, comportamiento, params) {\n          out_hacer(comportamiento, JSON.stringify(params));\n        }\n\n        function main() {\n          ".concat(codigo, "\n        }\n\n        main();\n      "));
    }
  });

  _exports.default = _default;
});