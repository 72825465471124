define("pilasbloques/templates/components/modal-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WQ+UAG7U",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[1,[22,\"title\"],false],[0,\" \"],[7,\"button\",false],[12,\"id\",\"cerrar-modal\"],[12,\"class\",\"pull-right btn btn-xs btn-default\"],[3,\"action\",[[23,0,[]],\"ocultar\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-close fa-2x\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/modal-title.hbs"
    }
  });

  _exports.default = _default;
});