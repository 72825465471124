define("pilasbloques/components/pilas-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-spinner'],
    centered: false
  });

  _exports.default = _default;
});