define("pilasbloques/templates/components/pilas-solution-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ErvMZCJM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[7,\"input\",true],[10,\"id\",\"cargarActividadInput\"],[10,\"accept\",\".spbq\"],[10,\"class\",\"hidden\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[7,\"a\",true],[10,\"id\",\"placeholder\"],[10,\"class\",\"hidden\"],[8],[0,\"..\"],[9],[0,\"\\n\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[3,\"action\",[[23,0,[]],\"abrirSolucion\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-folder-open-o\"],[8],[9],[0,\" Abrir\"],[9],[0,\"\\n\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[3,\"action\",[[23,0,[]],\"guardarSolucion\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-download\"],[8],[9],[0,\" Guardar\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/pilas-solution-file.hbs"
    }
  });

  _exports.default = _default;
});