define("pilasbloques/routes/desafios/curso-alumno", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* Esta ruta es una especialización de la ruta Nombre,
   * ya que hace algo muy similar, pero esconde elementos
   * de la interfaz y permite guardar la solución en un
   * backend de datos.
   */
  var _default = Ember.Route.extend({
    cursoAPI: Ember.inject.service(),
    model: function model(params) {
      params.nombre = this.decodificarHash(params.hash).actividad;
      return this.store.findAll("desafio").then(function (data) {
        // TODO: reemplazar la linea anterior (findAll) y la siguiente
        //       por una consulta a ember-data más específica, como la que
        //       realiza findRecord, que solo debería traer un solo registro.
        //
        //       (esto está así ahora porque se debe corregir mirage antes).
        var model = data.findBy('nombre', params.nombre);

        if (!model) {
          throw new Error("No existe una actividad con el nombre ".concat(params.nombre));
        }

        return model;
      });
    },

    /* Agrega los parámetros decodificados del hash al modelo. */
    afterModel: function afterModel(model, transition) {
      var hash = this.obtener_hash_desde_transition(transition);
      var valores = this.decodificarHash(hash);
      model.idAlumno = valores.idAlumno;
      model.hash = valores.hashCompleto;
      return this.cursoAPI.obtener_solucion_xml_desde_hash(model.hash).then(function (solucion_xml) {
        model.set("solucion", btoa(solucion_xml));
      }).catch(function () {
        console.warn("Ha fallado solicitar la solución al servidor, esto es porque el alumno no hay guardado nunca.");
        return null;
      });
    },
    obtener_hash_desde_transition: function obtener_hash_desde_transition(transition) {
      return transition.params[this.routeName].hash;
    },
    decodificarHash: function decodificarHash(hash) {
      var hashString = atob(hash);
      var valores = hashString.split("-");

      if (valores.length !== 3) {
        throw Error("Hash con formato de piezas incorrecto: ".concat(hashString));
      }

      return {
        actividad: valores[0],
        idAlumno: valores[1],
        hashMoodle: valores[2],
        hashCompleto: hash
      };
    },
    activate: function activate() {
      this.ocultarLayout();
    },
    ocultarLayout: function ocultarLayout() {
      this.controllerFor('application').set('layout', false);
    },
    actions: {
      cuandoEjecuta: function cuandoEjecuta(codigoXML) {
        var nombre = this.get('currentModel.nombre');
        var hash = this.get('currentModel.hash');
        var idAlumno = this.get('currentModel.idAlumno');
        var parametros = {
          actividad: nombre,
          hash: hash,
          idAlumno: idAlumno,
          codigo_xml: codigoXML
        };
        this.cursoAPI.guardar(parametros).catch(function (reason) {
          console.error(reason);
          alert("Se a producido un error al guardar, por favor volvé a intentar.");
        });
      }
    }
  });

  _exports.default = _default;
});