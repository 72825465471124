define("pilasbloques/controllers/desafio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    pilas: Ember.inject.service(),
    queryParams: ['codigo'],
    codigo: "",
    codigoJavascript: '',
    actions: {
      cuandoCargaPilas: function cuandoCargaPilas()
      /*pilas*/
      {},
      onChangeWorkspace: function onChangeWorkspace()
      /*codigoDelWorkspace*/
      {}
    }
  });

  _exports.default = _default;
});