define("pilasbloques/components/pilas-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    href: null,
    inElectron: false,
    didInsertElement: function didInsertElement() {
      this.set('inElectron', typeof process !== "undefined");
    },
    actions: {
      abrirConNavegadorExterno: function abrirConNavegadorExterno(url) {
        var _require = require("electron"),
            shell = _require.shell;

        shell.openExternal(url);
      }
    }
  });

  _exports.default = _default;
});