define("pilasbloques/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /*jshint esversion: 6 */
  function _default(server) {
    server.loadFixtures();
  }
});