define("pilasbloques/routes/desafio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      codigo: {
        replace: true
      }
    },
    pilas: Ember.inject.service()
  });

  _exports.default = _default;
});