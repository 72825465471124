define("pilasbloques/templates/components/pilas-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iAprn5Sg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[1,[28,\"pilas-spinner\",null,[[\"centered\"],[true]]],false],[0,\"\\n\\n\"],[7,\"iframe\",true],[10,\"src\",\"pilas.html\"],[10,\"width\",\"100%\"],[10,\"height\",\"100%\"],[10,\"class\",\"iframe-pilas-canvas\"],[10,\"id\",\"innerIframe\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/pilas-canvas.hbs"
    }
  });

  _exports.default = _default;
});