define("pilasbloques/controllers/acercade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notificador: Ember.inject.service(),
    hayActualizacion: Ember.computed.alias('notificador.hayActualizacion'),
    versionMasReciente: Ember.computed.alias('notificador.versionActual'),
    actions: {
      visitarWeb: function visitarWeb() {
        var url = "http://bloques.pilas-engine.com.ar";
        window.open(url);
      }
    }
  });

  _exports.default = _default;
});