define("pilasbloques/templates/components/pilas-notificador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c/oVisCv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"pilas-notificador \",[28,\"if\",[[24,[\"hayActualizacion\"]],\"pilas-notificador-visible\",\"pilas-notificador-oculto\"],null]]]],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"pilas-notificador-cerrar\"],[3,\"action\",[[23,0,[]],\"cerrar\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-close\"],[8],[9],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"Hay una nueva versión disponible para descargar.\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Visitá \"],[4,\"pilas-link\",null,[[\"href\"],[[24,[\"linkDescarga\"]]]],{\"statements\":[[0,\"nuestra web para más información.\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/pilas-notificador.hbs"
    }
  });

  _exports.default = _default;
});