define("pilasbloques/components/pilas-notificador", ["exports", "pilasbloques/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    servicioNotificador: null,
    hayActualizacion: false,
    tagName: '',
    linkDescarga: _environment.default.linkDeDescarga,
    didInsertElement: function didInsertElement() {
      var inElectron = typeof process !== "undefined";

      if (this.servicioNotificador && inElectron) {
        /* Solo si está en la versión offline, sobre electron, espera 5 segundos
          * y consulta si existe una versión nueva para descargar. */
        Ember.run.later(this, function () {
          this.consultarSiExisteVersionNueva();
        }, 5000);
      }
    },
    consultarSiExisteVersionNueva: function consultarSiExisteVersionNueva() {
      var _this = this;

      this.servicioNotificador.consultar().then(function (data) {
        if (data.hayActualizacion) {
          _this.set('hayActualizacion', true);

          _this.set('versionMasReciente', data.version);

          console.log("Hay una actualizaci\xF3n disponible. La versi\xF3n ".concat(data.version, "."));
        } else {
          console.log("Se consult\xF3 buscando una nueva versi\xF3n, pero el servidor inform\xF3 la versi\xF3n ".concat(data.version, " as\xED que no hace falta actualizar..."));
        }
      }, function (error) {
        console.warn("Se quiso consultar una nueva versión pero falló el request", error);
      });
    },
    actions: {
      cerrar: function cerrar() {
        this.set('hayActualizacion', false);
      }
    }
  });

  _exports.default = _default;
});