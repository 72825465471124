define("pilasbloques/templates/acercade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zv9YWZfh",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"contenido-principal\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"contenedor-acercade\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"pilas-acerca-de\"],false],[0,\"\\n\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"Actualmente estás usando la versión \"],[7,\"code\",true],[8],[1,[22,\"app-version\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"hayActualizacion\"]]],null,{\"statements\":[[0,\"\\t\\t\\t \\t(y se puede actualizar a la versión \"],[1,[22,\"versionMasReciente\"],false],[0,\")\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t  \"],[7,\"button\",false],[12,\"class\",\"btn btn-warning\"],[3,\"action\",[[23,0,[]],\"visitarWeb\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-globe\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Visitar la web del proyecto\"],[9],[0,\"\\n\\t\\t  \"],[1,[22,\"pilas-update\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/acercade.hbs"
    }
  });

  _exports.default = _default;
});