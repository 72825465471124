define("pilasbloques/mirage/models/desafio", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jshint esversion: 6 */
  var _default = _emberCliMirage.Model.extend({
    grupo: (0, _emberCliMirage.belongsTo)('grupo')
  });

  _exports.default = _default;
});