define("pilasbloques/models/grupo", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    titulo: (0, _attr.default)('string'),
    desafios: (0, _relationships.hasMany)('desafio'),
    // , {inverseOf: 'grupo'})
    libro: (0, _relationships.belongsTo)('libro')
  });

  _exports.default = _default;
});