define("pilasbloques/controllers/desafios/curso-alumno", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    pilas: Ember.inject.service(),
    queryParams: ['codigo'],
    codigo: null,
    codigoJavascript: ''
  });

  _exports.default = _default;
});