define("pilasbloques/templates/desafios/desafio-por-nombre-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JvfOYU2w",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"contenido-principal\"],[8],[0,\"\\n\\n  \"],[7,\"h2\",true],[8],[0,\"Lo siento, algo salió mal...\"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"No existe un desafío con el nombre indicado en la URL. ¿Podrías\\n    corroborar la correctitud de la dirección a este desafío?.\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/desafios/desafio-por-nombre-error.hbs"
    }
  });

  _exports.default = _default;
});