define("pilasbloques/templates/desafios/curso-docente", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DA9/bGQz",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"pilas-editor\",null,[[\"pilas\",\"model\",\"modoDocente\",\"persistirSolucionEnURL\",\"codigo\",\"debeMostrarFinDeDesafio\"],[[24,[\"pilas\"]],[24,[\"model\"]],true,false,[24,[\"model\",\"solucion\"]],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/desafios/curso-docente.hbs"
    }
  });

  _exports.default = _default;
});