define("pilasbloques/components/pilas-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['navbar', 'navbar-default'],
    mostrarDialogoAyuda: false,
    actions: {
      abrirAyuda: function abrirAyuda() {
        this.set('mostrarDialogoAyuda', true);
      }
    }
  });

  _exports.default = _default;
});