define("pilasbloques/templates/components/pilas-splitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "db21lTGS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"splitter\"],[10,\"id\",\"splitter\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"over-splitter\"],[10,\"class\",\"over-splitter\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/pilas-splitter.hbs"
    }
  });

  _exports.default = _default;
});