define("pilasbloques/services/available-blocks-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /// Este service deshabilita los bloques que no estén disponibles para una actividad
  var _default = Ember.Service.extend({
    globalAvailableBlockTypes: ["al_empezar_a_ejecutar", "numero", "required_value", "required_statement"],
    procedureRelatedBlockTypes: ["procedures_defnoreturn", "procedures_callnoreturn", "variables_get", "param_get"],
    disableNotAvailableBlocksInWorkspace: function disableNotAvailableBlocksInWorkspace(activityBlocks) {
      var _this = this;

      Blockly.getMainWorkspace().getAllBlocks().filter(function (block) {
        return !_this._isAvailable(block, activityBlocks);
      }).forEach(function (block) {
        return _this._disable(block);
      });
    },
    _isAvailable: function _isAvailable(block, activityBlocks) {
      return this._match(this.globalAvailableBlockTypes, block) || this._match(activityBlocks, block) || this._match(this.procedureRelatedBlockTypes, block) && this._includes(activityBlocks, "procedimiento");
    },
    _match: function _match(availableBlockTypes, currentBlock) {
      var _this2 = this;

      var aliases = currentBlock.aliases || [];
      return this._includes(availableBlockTypes, currentBlock.type) || aliases.some(function (alias) {
        return _this2._includes(availableBlockTypes, alias);
      });
    },
    _includes: function _includes(availableBlockTypes, type) {
      return availableBlockTypes.map(function (name) {
        return name.toLowerCase();
      }).includes(type.toLowerCase());
    },
    _disable: function _disable(block) {
      block.setDisabled(true);
      block.setWarningText("Este bloque no está disponible en esta actividad.");
    }
  });

  _exports.default = _default;
});