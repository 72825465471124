define("pilasbloques/templates/components/pilas-desafio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ARZ4waWa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"deshabilitado\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[10,\"class\",\"grayscale semi-transparente\"],[11,\"src\",[29,[\"images/desafios/\",[24,[\"model\",\"nombreImagen\"]]]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ribbon right gray semi-transparente\"],[8],[7,\"a\",true],[8],[0,\"Muy pronto\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"query\",\"route\",\"model\"],[[28,\"hash\",null,[[\"codigo\"],[\"\"]]],\"desafio\",[24,[\"model\"]]]],{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"class\",\"desafio-img\"],[11,\"src\",[29,[\"images/desafios/\",[24,[\"model\",\"nombreImagen\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"unless\",[[24,[\"model\",\"grupo\",\"libro\",\"desafiosCortos\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"pilas-desafio-titulo\"],[8],[1,[24,[\"model\",\"titulo\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/pilas-desafio.hbs"
    }
  });

  _exports.default = _default;
});