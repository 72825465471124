define("pilasbloques/templates/components/pilas-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N0O8/Is+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"spinner \",[28,\"if\",[[24,[\"centered\"]],\"spinner-centered\"],null]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/components/pilas-spinner.hbs"
    }
  });

  _exports.default = _default;
});