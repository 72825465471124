define("pilasbloques/templates/libros/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lRqRtTKA",
    "block": "{\"symbols\":[\"libro\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"contenido-principal\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"box\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[7,\"img\",true],[10,\"class\",\"main-logo\"],[10,\"src\",\"images/main-logo.png\"],[10,\"width\",\"468\"],[10,\"height\",\"262\"],[8],[9],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      ¡Hola! Te damos la bienvenida a \"],[7,\"strong\",true],[8],[0,\"Pilas Bloques\"],[9],[0,\",\\n      una herramienta para aprender a programar.\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Los desafíos que ofrecemos están organizados en dos grupos:\\n    \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[4,\"unless\",[[23,1,[\"oculto\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"pilas-libro\",null,[[\"libro\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Visitá la sección \"],[4,\"link-to\",null,[[\"route\"],[\"acercade\"]],{\"statements\":[[7,\"strong\",true],[8],[0,\"Acerca de\"],[9]],\"parameters\":[]},null],[0,\"\\n      para conocer más en detalle de qué se trata esta herramienta y quién la desarrolló.\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Si necesitás ayuda, presioná (\"],[7,\"i\",true],[10,\"class\",\"fa fa-question\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\") ubicado en la parte superior derecha\\n      de la pantalla.\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilasbloques/templates/libros/index.hbs"
    }
  });

  _exports.default = _default;
});